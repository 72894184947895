import React, { useEffect } from 'react'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

import Scroll from './scroll'

const ScrollContainer = ({ children }) => {
  const locomotiveScroll = useLocomotiveScroll()

  useEffect(() => {
    console.log('Updating locomotive')
    if (locomotiveScroll.isReady) {
      const scroll = new Scroll(locomotiveScroll.scroll)

      return () => scroll.destroy()
    }
  }, [locomotiveScroll.isReady, locomotiveScroll.scroll])

  return <>{children}</>
}

export default ScrollContainer
