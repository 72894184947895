import React, { useRef, useState, useEffect } from 'react'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import { Helmet } from 'react-helmet'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import classNames from 'classnames';

import '../../styles/index.scss'
import ScrollContainer from './ScrollContainer'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const showPage = () => {
      setIsVisible(true)
    }
    document.fonts.ready.then(showPage).catch(showPage);
    setTimeout(showPage, 5000);
  })

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/social.png`}
        />
      </Helmet>
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          lerp: 0.08,
          mobile: {
            smooth: true
          },
          tablet: {
            smooth: true
          },
        }}
        containerRef={containerRef}
      >
        <div data-scroll-container ref={containerRef}
          className={classNames('page-wrapper', {'is-visible': isVisible})}>
          <ScrollContainer>{children}</ScrollContainer>
        </div>
      </LocomotiveScrollProvider>
    </>
  )
}

export default TemplateWrapper
