import throttle from '../../utils/throttle'

export default class Scroll {
  constructor(scroll) {
    this.scroll = scroll

    this.isScrolled = false;
    this.themeLines = [...document.querySelectorAll('[data-theme]')]

    this.scroll.on('scroll', throttle(this.scrollHandler, 300))
  }

  scrollHandler = (event) => {
    const scrollY = event.scroll.y

    const isScrolled = scrollY > 30
    if (isScrolled !== this.isScrolled) {
      this.isScrolled = isScrolled
      window.dispatchEvent(new CustomEvent('pr-is-scrolled', {
        detail: {
          isScrolled: this.isScrolled
        }
      }))
    }

    let theme = null
    this.themeLines.forEach((themeLine) => {
      const rect = themeLine.getBoundingClientRect()
      if (rect.top <= window.innerHeight * 0.6) {
        theme = themeLine.dataset.theme
      }
    })
    document.body.setAttribute('theme', theme)
  }

  destroy = () => {
    this.scroll.destroy()
  }
}
